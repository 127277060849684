
import { Options, Vue } from "vue-class-component";
import Page from "@/components/Page.vue";
import { Constants } from "@/constants/constants";

@Options({
  components: {
    Page,
  },
  data: () => ({
    /**
     * The page type id
     */
    pageTypeId: Constants.EmployeeHomePageTypeId,
  }),
  mounted() {
    this.$refs.page.getPageSections();
  },
})
export default class EmployeeHome extends Vue {}
